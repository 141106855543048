<template>
  <div class="home">
    <div class="header_top">
      <handleback></handleback>
      <div>账户修改</div>
      <div>玩法说明</div>
    </div>
    <div class="main">
      <div class="space_between mian_i" @click="handlechange(1);playAudio()">
        <div>修改头像</div>
        <div>
          去修改
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="space_between mian_i" @click="handlechange(2);playAudio()">
        <div>修改昵称</div>
        <div>
          去修改
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <!-- <div class="space_between mian_i" @click="handlechange(3);playAudio()">
        <div>修改手机号</div>
        <div>
          去修改
          <i class="el-icon-arrow-right"></i>
        </div>
      </div> -->
    </div>

    <div class="out" v-if="onchange">
      <div class="column out_top">
        <div class="out_title">{{ title }}</div>

        <div class="out_nickname" v-if="title=='修改头像'">
          <input type="file" @change="uploadImage" />
          <!-- <div size="small" type="primary" style="font-size: 12px" @click="uploadImage">点击更换头像</div> -->
        </div>
        <div class="out_nickname" v-if="title=='修改昵称'">
          <input type="text" placeholder="请输入昵称" v-model="as" />
        </div>
        <div class="out_nickname" v-if="title=='修改手机号'">
          <input type="text" placeholder="请输入手机号" v-model="phoneNumber" />
        </div>
        <div class="out_nickname space_between" v-if="title=='修改手机号'">
          <input type="text" placeholder="请输入验证码" v-model="code" />
          <div @click="handlesend();playAudio()" style="width: 30%;">{{buttonText}}</div>
        </div>
        <div class="out_btn space_between">
          <div class="btn_q center btn_click" @click="onchange=false;playAudio()">取消</div>
          <div class="btn_a center btn_click" @click="handlesubmit();playAudio()">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateUserDetails, getcode, updataavatar } from "@/api/index";
export default {
  data() {
    return {
      onchange: false,
      counting: false,
      buttonText: "发送验证码",
      countdownTime: 60,
      code: "",
      as: "",
      phoneNumber: "",
      title: "修改手机号"
    };
  },
  methods: {
    playAudio(){
      this.$store.commit("playAudio");
    },
    uploadImage(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);

      updataavatar(formData).then(res => {
        if(res.data.code){
              console.log("成功")
             }
      });
    },
    throttle(func, limit) {
      let inThrottle;
      return function() {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    },
    handlesend() {
      const phoneRegex = /^1[3456789]\d{9}$/;

      if (phoneRegex.test(this.phoneNumber)) {
        if (this.counting) {
          return;
        }
        getcode({
          phoneNumber: this.phoneNumber,
          type: "3"
        }).then(res => {
          if(res.data.code){
              console.log("成功")
             }
        });
        this.counting = true;
        this.buttonText = `${this.countdownTime}s`;
        const interval = setInterval(() => {
          this.countdownTime--;
          if (this.countdownTime === 0) {
            clearInterval(interval);
            this.counting = false;
            this.buttonText = "发送验证码";
            this.countdownTime = 60;
          } else {
            this.buttonText = `${this.countdownTime}s`;
          }
        }, 1000);
      } else if (this.phoneNumber == "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
          customClass: "log_warning"
        });
      } else {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          customClass: "log_warning"
        });
      }
    },
    handlechange(res) {
      if (res == 1) {
        this.title = "修改头像";
      } else if (res == 2) {
        this.title = "修改昵称";
      } else if (res == 3) {
        this.title = "修改手机号";
      }
      this.onchange = true;
    },
    handlesend1() {


      getcode({
        phoneNumber: this.phoneNumber,
        type: "3"
      }).then(res => {
        if(res.data.code){
              console.log("成功")
             }
      });
    },
    handlesubmit() {
      if (this.title == "修改昵称") {
        let nickName = { nickName: this.as };
        updateUserDetails(nickName).then(res => {
          if (res.data.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
              customClass: "log_success"
            });
            this.onchange = false;
          } else {
            this.onchange = false;
          }

        });
      } else if (this.title == "修改手机号") {
        let phoneNumber = { phoneNumber: this.as, code: 6627 };
        updateUserDetails(phoneNumber).then(res => {

          if (res.data.code == 200) {
            this.$message({
              message: "修改成功",
              type: "success",
              customClass: "log_success"
            });
            this.onchange = false;
          } else {
            this.onchange = false;
          }
        });
      } else if (this.title == "修改头像") {
        this.onchange = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  width: 95%;
  margin: 0 auto;
  .header_top {
    width: 100%;
    height: 56px;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div:first-child {
    }
    div:last-child {
      color: #fff;
      font-size: 12px;
      text-decoration: underline;
      line-height: 1.5;
      opacity: 0;
    }
  }
}
.main {
  width: 100%;
  height: 175px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  margin: 0 auto;
  overflow: hidden;
  .mian_i {
    width: 100%;
    margin: 0 auto;
    padding: 0 5%;
    height: 59px;
    border-bottom: 1px solid #323436;
    font-size: 15px;
    font-weight: 400;
    color: #727477;
  }
}
.out {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  text-align: left;
  .out_top {
    width: 95%;
    margin: 0 auto;
    position: relative;
    height: 245px;
    background: #101115;
    border-radius: 23px 23px 23px 23px;
    margin-top: 211px;
    overflow: hidden;
    justify-content: space-around;
    padding: 0 32px;
    .out_title {
      font-size: 28px;
    }
    .out_nickname {
      width: 100%;

      height: 56px;
      padding: 0 2% 0 5%;
      background: #323436;
      border-radius: 70px 70px 70px 70px;
      position: relative;
      z-index: 1;

      input {
        width: 100%;
        height: 100%;
        border-radius: 35px;
        border: 1px solid #323436;
        color: #fff;
        font-size: 21px;
        background-color: #323436;
        text-indent: 10px;
        outline: none;
        text-align: center;
      }
    }
    .out_btn {
      width: 100%;
      font-size: 21px;
      position: relative;
      .btn_q {
        width: 47%;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(114, 116, 119, 0.5) 0%,
          rgba(114, 116, 119, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #727477;
      }
      .btn_a {
        width: 47%;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #25f484;
      }
    }
  }
  .out_top::before {
    content: ""; /*必须设置content属性*/
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -215deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 41%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>